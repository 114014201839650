import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import VueFrontendLibrary from 'vue-frontend-library'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/filters'
import constant from './utils/constant'

import TopNav from "./components/TopNav";
import FootNav from "./components/FootNav";
import './assets/main.scss'

Vue.use(VueClipboard)
Vue.use(VueFrontendLibrary, {
    axios: {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'api-agent': process.env.NODE_ENV === 'production' ? 'JX-' + new Date().getFullYear()+'.'+("0"+(new Date().getMonth()+1)).slice(-2)+'.'+("0"+new Date().getDate()).slice(-2) : 'JX-2099.12.12'
        },
    }
})

Vue.prototype.$constant = Object.assign({}, Vue.prototype.$constant, constant)

Vue.config.productionTip = false

Vue.component('TopNav', TopNav)
Vue.component('FootNav', FootNav)

//个性化请求拦截器
Vue.prototype.$http.interceptors.request.use(function (config) {
    return config
}, error => {
    return Promise.reject(error)
})

//个性化响应拦截器
Vue.prototype.$http.interceptors.response.use(function (response) {
    const data = response.data ||{}

    if(data.status === 'success' || data.login === '1') {
        return data
    }
    else {
        return Promise.reject(data.errmsg||(data.msgs||[]).map(item=>item.text).join(',')||data.msg||'操作失败！')
    }

}, function (error) {
    const statusCode = (error.response || {}).status

    if (statusCode === 403) {
        const currentRoute = router.currentRoute || {}
        if (currentRoute.name !== 'LoginView' && currentRoute.name !== 'UtilLogonView') {
            router.push({
                name: currentRoute.path.indexOf('/lender') !== -1 ? 'LenderLogonView' : 'IndexView'     //资方和微信端用户跳转到不同登录页面
            })
                .then(() => {

                })
                .catch(() => {
                })
        }
    }

    return Promise.reject(error)
})

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
