import Vue from 'vue'
import Vuex from 'vue-frontend-library/node_modules/vuex'

Vue.use(Vuex)

const state = {
    userInfo: {
        u__roles: ''
    }
}
const getters = {

}
const mutations = {
    setUserInfo: (state, userInfo) => {
        state.userInfo = userInfo
    }
}
const store = new Vuex.Store({
    state,
    getters,
    mutations
})

export default store
