<template>
    <ul class="footnav of wechat">
        <li class="home">
            <router-link :to="{name: 'PortalView'}">
                <i></i>
                <p>首页</p>
            </router-link>
        </li>
        <li :class="['mesg', {on: path.indexOf('message') }]">
            <router-link :to="{name: 'MessageView'}">
                <i></i>
                <p>消息</p>
            </router-link>
        </li>
        <li :class="['my',  {on: path.indexOf('portal') }]">
            <router-link :to="{name: 'MyselfView'}">
                <i></i>
                <p>我的</p>
            </router-link>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "FootNav",
        data() {
            return {

            }
        },
        computed: {
            path() {
                return this.$route.path
            }
        }
    }
</script>

<style scoped>

</style>
