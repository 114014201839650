<template>
    <router-view></router-view>
</template>

<script>

    export default {
        name: 'app',
        data() {
            return {
            }
        }
    }
</script>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
    .wechat .el-message-box {
        width: 95%
    }
</style>
