export default {
    pageSize: 10,
    applicationStatusObj : {
        'Open': '未提交',
        'Draft' : '未提交',
        'Pending Approval' : '待审核',
        'Rejected': '已拒绝',
        'Submitted': '待受理',
        'Ready': '已受理',
        'Completed': '已完成',
        'JX-Rejected': '已拒绝'
    },
    interestTypeObj: {
        'Yearly' : '年利率',
        'Monthly' : '月利率'
    },
    repaymentFormulaObj: {
        DQHK: '到期一次还款付息',
        DEBX: '等额本息',
        DBDX: '等本等息',
        XXHB: '先息后本'

    },
    financingStatusObj: {
        Processing: '进行中',
        Settled: '已还清',
        Pending: '待处理',
    },
    financingStatus:{
        Processing: '还款中',
        Ready: '待放款',
        Settled: '已还清',
        Rejected:'已作废',
    },
    attstatus:{
        Successful:'导入成功',
        Failed:'导入失败',
        Ready:'待处理',
        Processing:'处理中',
    },
    interestType:{
        Yearly: '年利率',
        Monthly: '月利率',
        Daily: '日利率',
    },
    FinancingList:{
        Financing_Processing: '还款中',
        Financing_Ready: '待放款',
        Financing_Settled: '已还清',
        Financing_Rejected:'已作废',
    },
    financingRequestStatusObj: {
        Ready: '待受理',
        Open: '待分配',
        Void:   '已作废',
        Completed: '已受理'
    },
    customerSex:{
        'Sex_M': '男',
        'Sex_F': '女',
    },
    repaymentStatus:{
        Processing: '已放款',
        Settled: '已还清',
        Pending: '待处理',
        Ready: '待生效',
        plan__Pending:'未还',
        plan__Settled:'已还',
    },
    EquitySwapTxList:{
        YZZ: '已债转',
        CLWC: '已清收',
        SSZ: '诉讼中',
        'Pending Approval': '待审核',
        Rejected:'审核不通过',
    },
}
