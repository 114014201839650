<template>
    <div class="headtop of">
        <div class="headtopL" @click="$router.back()"></div>
        <div class="headtopM">
            <slot name="default"></slot>
        </div>
        <div class="headtopR" @click="$router.push({path: '/pages'})"></div>
    </div>
</template>

<script>
    export default {
        name: "TopNav",
        data() {
            return {

            }
        }
    }
</script>

<style scoped>

</style>
