import Vue from 'vue'
import VueRouter from "vue-frontend-library/node_modules/vue-router";
import VCharts from 'v-charts'

Vue.use(VueRouter)
Vue.use(VCharts)

const component = {
    template: '<router-view></router-view>'
}

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'IndexView',
            component: () => import('../views/wechat/index'),
            beforeEnter: (to, from ,next) => {
                if(process.env.NODE_ENV == 'production') {
                    next({name: 'LoginView'})
                }
                else {
                    next()
                }
            }
        },
        {
            path: '/utils',
            name: 'UtilView',
            component,
            children: [
                {
                    path: '',
                    name: 'UtilsIndexView',
                    component: () => import('../views/utils/index')
                },
                    {
                    path: 'logon',
                    name: 'UtilLogonView',
                    component: () => import('../views/utils/logon')
                },
                {
                    path: 'admin',
                    name: 'UtilAdminView',
                    component: ()=> import('../views/utils/PagesView'),
                    children: [
                        {
                            path: '',
                            redirect: {
                                name: 'FinancingList'
                            }
                        },
                        {
                            path: 'home',
                            component: ()=> import('../views/utils/views/admin/HomeView')
                        },
                        {
                            path: 'Fang',
                            name: 'FangView',
                            component,
                            children: [
                                {
                                    path: 'FirstFinancingList',
                                    name: 'FirstFinancingList',
                                    component: ()=> import('../views/utils/views/fang/FirstFinancingList'),
                                },
                                {
                                    path: 'View',
                                    component: ()=> import('../views/utils/views/fang/FirstFinancingView')
                                },
                                {
                                    path: 'Upload',
                                    component: ()=> import('../views/utils/views/fang/FirstFinancingUpload')
                                },
                                {
                                    path: 'Upload2',
                                    component: ()=> import('../views/utils/views/fang/FirstFinancingUpload2')
                                },
                                {
                                    path: 'AttachmentList',
                                    component: ()=> import('../views/utils/views/fang/AttachmentList')
                                },
                                {
                                    path: 'FinancingList',
                                    name: 'FinancingList',
                                    component: ()=> import('../views/utils/views/fang/FinancingList')
                                },
                                {
                                    path: 'InfoSupplyList',
                                    component: ()=> import('../views/utils/views/fang/InfoSupplyList')
                                }
                            ]
                        },
                        {
                            path: 'Financing',
                            name: 'FangView',
                            component,
                            children: [
                                {
                                    path: 'FinancingList',
                                    component: ()=> import('../views/utils/views/fang/FinancingList')
                                },
                                {
                                    path: 'EquitySwapTxList',
                                    name: 'EquitySwapTxList',
                                    component: ()=> import('../views/utils/views/fang/EquitySwapTxList')
                                },
                            ]
                        },
                        {
                            path: 'PcApplication',
                            name: 'PcApplicationView',
                            component,
                            children: [
                                {
                                    path: 'PcApplicationList',
                                    name: 'PcApplicationListView',
                                    component: ()=> import('../views/utils/views/fang/ApplicationList')
                                },
                                {
                                    path: 'PcApplicationForm',
                                    name: 'PcApplicationForm',
                                    component: ()=> import('../views/utils/views/fang/ApplicationForm')
                                },
                                {
                                    path: 'PcApplicationFormView',
                                    name: 'PcApplicationFormView',
                                    component: ()=> import('../views/utils/views/fang/ApplicationFormView')
                                },
                            ]
                        },
                    ]
                },

            ]
        },
        {
            path: '/lender',
            name: 'LenderView',
            component,
            children: [
               /* {
                    path: '',
                    name: 'UtilsIndexView',
                    component: () => import('../views/utils/index')
                },*/
                {
                    path: 'logon',
                    name: 'LenderLogonView',
                    component: () => import('../views/lender/logon')
                },
                {
                    path: 'adminLender',
                    name: 'AdminLenderView',
                    component: ()=> import('../views/lender/PagesView'),
                    children: [
                        {
                            path: '',
                            redirect:'lender'
                        },
                        {
                            path: 'lender',
                            name: 'lenderView',
                            component,
                            children: [
                                {
                                    path: '',
                                    redirect:'ApplyingCustomerList'
                                },
                                {
                                    path: 'ApplyingCustomerList',
                                    name: 'ApplyingCustomerListView',
                                    component: ()=> import('../views/lender/views/ApplyingCustomerList'),
                                },
                                {
                                    path: 'ApplyingCustomerView',
                                    name: 'ApplyingCustomerViewView',
                                    component: ()=> import('../views/lender/views/ApplyingCustomerView')
                                },
                                {
                                    path: 'AcceptAllocationList',
                                    component: ()=> import('../views/lender/views/AcceptAllocationList'),
                                },
                                {
                                    path: 'AcceptAllocationView',
                                    component: ()=> import('../views/lender/views/AcceptAllocationView'),
                                },
                                {
                                    path: 'FinancingList',
                                    component: ()=> import('../views/lender/views/FinancingList'),
                                },
                                {
                                    path: 'FinancingView',
                                    component: ()=> import('../views/lender/views/FinancingView')
                                },
                                {
                                    path: 'RepaymentPlanList',
                                    component: ()=> import('../views/lender/views/RepaymentPlanList'),
                                },
                                {
                                    path: 'RepaymentPlanRepoetList',
                                    component: ()=> import('../views/lender/views/RepaymentPlanRepoetList'),
                                },
                                {
                                    path: 'LenderRebateList',
                                    component: ()=> import('../views/lender/views/LenderRebateList'),
                                },
                            ]
                        },
                        {
                            path: 'report',
                            name: 'reportView',
                            component,
                            children: [
                                {
                                    path: '',
                                    redirect:'RepaymentPlanRepoetList'
                                },

                                {
                                    path: 'RepaymentPlanRepoetList',
                                    component: ()=> import('../views/lender/views/RepaymentPlanRepoetList'),
                                },
                                {
                                    path: 'LenderRebateList',
                                    component: ()=> import('../views/lender/views/LenderRebateList'),
                                },
                            ]
                        },
                        {
                            path: 'Financing',
                            name: 'FangView',
                            component,
                            children: [
                                {
                                    path: 'FinancingList',
                                    component: ()=> import('../views/utils/views/fang/FinancingList')
                                },
                            ]
                        },
                    ]
                },/*
*/
            ]
        },
        {
            path: '/login',
            name: 'LoginView',
            component: () => import('../views/wechat/login')
        },
        {
            path: '/authorize-qr/:token',
            name: 'AuthorizeQRView',
            component: () => import('../views/wechat/AuthorizeQR')
        },
        {
            path: '/user-protocol',
            name: 'UserProtocol',
            component: () => import('../views/wechat/UserProtocol')
        },
        {
            path: '/error',
            name: 'ErrorView',
            component: () => import('../views/wechat/Error')
        },
        {
            path: '/pages',
            component: () => import('../views/wechat/pages'),
            children: [
                {
                    path: '',
                    redirect: 'portal'
                },
                {
                    path: 'message',
                    name: 'MessageView',
                    component: () => import('../views/wechat/pages/MessageList')
                },
                {
                    path: 'portal',
                    name: 'PortalView',
                    component: () => import('../views/wechat/pages/PortalDealer')
                },
                {
                    path: 'portal-customer',
                    name: 'PortalCustomerView',
                    component: () => import('../views/wechat/pages/PortalCustomer')
                },
                {
                    path: 'portal-history',
                    name: 'PortalHistoryView',
                    component: () => import('../views/wechat/pages/PortalHistory')
                },
                {
                    path: 'application-form/:applicationId?',
                    name: 'ApplicationFormView',
                    component: () => import('../views/wechat/pages/ApplicationForm')
                },
                {
                    path: 'application-list',
                    name: 'ApplicationListView',
                    component: () => import('../views/wechat/pages/ApplicationList')
                },
                {
                    path: 'customer-list',
                    name: 'CustomerListView',
                    component: () => import('../views/wechat/pages/CustomerList')
                },
                {
                    path: 'financing-list',
                    name: 'FinancingListView',
                    component: () => import('../views/wechat/pages/FinancingList')
                },
                {
                    path: 'financing-detail/:financingId',
                    name: 'FinancingDetailView',
                    component: () => import('../views/wechat/pages/FinancingDetail')
                },
                {
                    path: 'myself',
                    name: 'MyselfView',
                    component: () => import('../views/wechat/pages/Myself')
                },
                {
                    path: 'AboutUs',
                    name: 'AboutUs',
                    component: () => import('../views/wechat/pages/AboutUs')
                },
                {
                    path: 'ReviseRepaymentPlan',
                    name: 'ReviseRepaymentPlan',
                    component: ()=> import('../views/wechat/pages/ReviseRepaymentPlan')
                },
                {
                    path: 'ProcessingFinancingSelect',
                    name: 'ProcessingFinancingSelect',
                    component: ()=> import('../views/wechat/pages/ProcessingFinancingSelect')
                },
                {
                    path: 'NormalRepaymentPlan',
                    name: 'NormalRepaymentPlan',
                    component: ()=> import('../views/wechat/pages/NormalRepaymentPlan')
                },
                {
                    path: 'LoanReminder',
                    name: 'LoanReminder',
                    component: ()=> import('../views/wechat/pages/LoanReminder')
                },
                {
                    path: 'FinancingRepaymentDetailList/:financingId',
                    name: 'FinancingRepaymentDetailList',
                    component: ()=> import('../views/wechat/pages/FinancingRepaymentDetailList')
                },
                {
                    path: 'FinancingRepaymentDetail/:financingId',
                    name: 'FinancingRepaymentDetail',
                    component: ()=> import('../views/wechat/pages/FinancingRepaymentDetail')
                },
                {
                    path: 'dealer',
                    component,
                    children: [
                        {
                            path: 'financing-request-list',
                            name: 'DealerFinancingRequestList',
                            component: ()=> import('../views/wechat/pages/dealer/FinancingRequestList')
                        },
                        {
                            path: 'financing-overdue-list',
                            name: 'DealerFinancingOverdueList',
                            component: ()=> import('../views/wechat/pages/dealer/FinancingOverdueList')
                        }
                    ]
                },
                {
                    path: 'RepayByDealer',
                    name: 'RepayByDealer',
                    component: ()=> import('../views/wechat/pages/RepayByDealer')
                },
                {
                    path: 'RepayByDealerFinancingDetail/:financingId',
                    name: 'RepayByDealerFinancingDetail',
                    component: ()=> import('../views/wechat/pages/RepayByDealerFinancingDetail')
                },
                {
                    path: 'RepayByDealerFinancingRepaymentDetail/:repaymentId',
                    name: 'RepayByDealerFinancingRepaymentDetail',
                    component: ()=> import('../views/wechat/pages/RepayByDealerFinancingRepaymentDetail')
                }
            ]
        },
        {
            path: '/open',
            name: 'OpenCustomerIndex',
            component,
            children: [
                {
                    path: '',
                    name: 'OpenIndex',
                    component: ()=> import('../views/wechat/open/Index')
                },
                {
                    path: 'create-financing-request',
                    name: 'OpenCreateFinancingRequest',
                    component: ()=> import('../views/wechat/open/CreateFinancingRequest')
                },
                {
                    path: 'create-financing-request-success',
                    name: 'OpenCreateFinancingRequestSuccess',
                    component: ()=> import('../views/wechat/open/CreateFinancingRequestSuccess')
                },
                {
                    path: 'financing-request-list',
                    name: 'OpenFinancingRequestList',
                    component: ()=> import('../views/wechat/open/FinancingRequestList')
                },
                {
                    path: 'welcome',
                    name: 'OpenWelcome',
                    component: ()=> import('../views/wechat/open/Welcome')
                }
            ]
        }
    ]
})
