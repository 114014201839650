import Vue from 'vue'

const mask = function (str) {
    //强制转字符串
    str += ''

    if( /[0-9]{11,18}/.test(str) ) {
        //手机号
        if( str.length == 11 ) {
            str = str.substr(0, 3) + '********'
        }
        //身份证号
        else if(str.length == 18) {
            str = str.replace(str.substr(6, 8), '********')
        }

        return str
    }
}

Vue.filter('mask', mask)